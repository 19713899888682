import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Stack,
  Radio,
  RadioGroup,
  Textarea,
  IconButton,
  Text,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
  HamburgerIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import "../css/swal.css";
import { checkAllParamExist } from "../util/datatool";
import { setGlobalState } from "../share/share_states";
import { MdSave } from "react-icons/md";
import { getEvaluateSatisfaction, retrieveQuestionnaireByCHeckpointID, saveQuestions, updateEvaluateSatisfaction } from "../util/api";
import RatingModal from "../modals/RatingModal.jsx";
import CheckpointProductList from "./CheckpointProductList.jsx";

const QuestionList = () => {
  const [searchParams] = useSearchParams();
  const initialQuestions = [
    /*{
      question: "What is your favorite color?",
      type: "single_selected_choices",
      choices: ["Red", "Blue", "Green"],
      status: "drafted",
      code: "Ax3BC"
    },
    {
      question: "How many fingers does a typical human have?",
      type: "filling",
      status: "drafted",
      code: "CG3SE"
    },*/
  ];

  const { isOpen, onOpen, onClose: onCloseEditModal } = useDisclosure();
  const { 
    isOpen: isRatingModalOpen, 
    onOpen: openRatingModal, 
    onClose: closeRatingModal 
  } = useDisclosure();

  const [previewIndex, setPreviewIndex] = useState(0);
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onClosePreview,
  } = useDisclosure();

  const [questions, setQuestions] = useState(initialQuestions);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentChoices, setCurrentChoices] = useState([]); // Updated variable name
  const [questionType, setQuestionType] = useState("single_selected_choices");
  const [answerType, setAnswerType] = useState("numeric");
  const [editingIndex, setEditingIndex] = useState(null);
  const [questionStatus, setQuestionStatus] = useState("drafted");
  const [questionCode, setQuestionCode] = useState(null);
  const [checkpointID, setCheckpointID] = useState(null);
  const [checkpointName, setCheckpointName] = useState(null);
  const [eventID, setEventID] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [isSatisfactionEnabled, setIsSatisfactionEnabled] = useState(false);

  useEffect(() => {
    async function getCheckpoint() {
      if (checkAllParamExist(searchParams, ["cid", "cname", "eid", "ename"])) {
        setCheckpointID(searchParams.get("cid"));
        setEventID(searchParams.get("eid"));
        setEventName(searchParams.get("ename"));
        setCheckpointName(searchParams.get("cname"));

        fetchQuestionaire(searchParams.get("cid"))
        fetchEvaluateSatisfaction(searchParams.get("cid"))
      }
    }

    getCheckpoint();
  }, []);

  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const fetchEvaluateSatisfaction = async (checkpoint_id) => {
    try {
      const res = await getEvaluateSatisfaction(checkpoint_id);
      const result = res.data;
      const evaluate_satisfaction = result.data.evaluate_satisfaction;
  
      // Set the switch state based on the fetched evaluate_satisfaction value
      setIsSatisfactionEnabled(evaluate_satisfaction === 1); // Assuming 1 = enabled, 0 = disabled
    } catch (error) {
      console.error("Error fetching evaluate_satisfaction:", error);
    }
  };

  const fetchQuestionaire = async (checkoint_id) => {
    try {
      setGlobalState("loadingtext", "loading, please wait...");
      setGlobalState("loading", true);
      const res = await retrieveQuestionnaireByCHeckpointID(checkoint_id)
      setQuestions(res.data.records)
      setGlobalState("loading", false);
    }
    catch(error){
      setGlobalState("loading", false);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Cannot load questions!",
      });
    }
  }

  const isFieldsFilled = () => {
    if (!currentQuestion.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in the question field.",
        customClass: {
          container: "swal-zindex",
        },
      });
      return false;
    }

    if (questionType === "single_selected_choices") {
      if (currentChoices.some((choice) => !choice.trim())) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill in all answer fields.",
          customClass: {
            container: "swal-zindex",
          },
        });
        return false;
      }
    }

    return true;
  };

  const handleQuestionTypeChange = (value) => {
    setQuestionType(value);

    // Reset currentChoices when switching from Multiple Choice to Answer Filling
    if (value === "filling") {
      setCurrentChoices([]);
    }
  };

  const handleMoveQuestionUp = (index) => {
    if (index > 0) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        [updatedQuestions[index], updatedQuestions[index - 1]] = [
          updatedQuestions[index - 1],
          updatedQuestions[index],
        ];
        return updatedQuestions;
      });
    }
  };

  const handleMoveQuestionDown = (index) => {
    if (index < questions.length - 1) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        [updatedQuestions[index], updatedQuestions[index + 1]] = [
          updatedQuestions[index + 1],
          updatedQuestions[index],
        ];
        return updatedQuestions;
      });
    }
  };

  const handleMoveAnswerUp = (index) => {
    if (index > 0) {
      setCurrentChoices((prevChoices) => {
        const updatedChoices = [...prevChoices];
        [updatedChoices[index], updatedChoices[index - 1]] = [
          updatedChoices[index - 1],
          updatedChoices[index],
        ];
        return updatedChoices;
      });
    }
  };

  const handleMoveAnswerDown = (index) => {
    if (index < currentChoices.length - 1) {
      setCurrentChoices((prevChoices) => {
        const updatedChoices = [...prevChoices];
        [updatedChoices[index], updatedChoices[index + 1]] = [
          updatedChoices[index + 1],
          updatedChoices[index],
        ];
        return updatedChoices;
      });
    }
  };

  const saveCheckpointQuestion = async () => {
    try {
      setGlobalState("loadingtext", "saving, please wait...");
      setGlobalState("loading", true);
      await saveQuestions(checkpointID, questions);
      setGlobalState("loading", false);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Questions have been saved to database!",
      });
    }
    catch(error){
      setGlobalState("loading", false);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Cannot save questions!",
      });
    }
  };

  const handleAddQuestion = () => {
    if (!isFieldsFilled()) {
      return;
    }

    if (
      questionStatus === "published" &&
      questionType === "single_selected_choices" &&
      currentChoices.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "For multiple-choice questions, at least one answer is required to activate the question.",
        customClass: {
          container: "swal-zindex",
        },
      });
      return;
    }

    if (editingIndex !== null) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[editingIndex] = {
          question: currentQuestion,
          type: questionType,
          choices: currentChoices, // Updated variable name
          status: questionStatus,
          code: questionCode
        };
        return updatedQuestions;
      });
      setEditingIndex(null);
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          question: currentQuestion,
          type: questionType,
          choices: currentChoices, // Updated variable name
          status: questionStatus,
          code: questionCode
        },
      ]);
    }

    setCurrentQuestion("");
    setCurrentChoices([]); // Updated variable name
    setQuestionType("single_selected_choices");
    setAnswerType("numeric");
    setQuestionStatus("drafted");
    setQuestionCode(makeid(10))
    onCloseEditModal(); // Move this after resetting currentChoices
  };

  const handleEditQuestion = (index) => {
    const selectedQuestion = questions[index];    
    setCurrentQuestion(selectedQuestion.question);
    setQuestionType(selectedQuestion.type);
    setCurrentChoices(selectedQuestion.choices); // Updated variable name
    setQuestionStatus(selectedQuestion.status);
    setQuestionCode(selectedQuestion.code);
    setEditingIndex(index);
    onOpen();
  };

  const showDeleteConfirmation = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this question!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteQuestion(index);
        Swal.fire("Deleted!", "Your question has been deleted.", "success");
      }
    });
  };

  const saveEvaluateSatisfaction = async (checkpoint_id, evaluate_satisfaction) => {
    try {
      const res = await updateEvaluateSatisfaction(checkpoint_id, evaluate_satisfaction);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update satisfaction setting!",
      });
    }
  };

  const handleDeleteQuestion = (index) => {
    setQuestions((prevQuestions) => [
      ...prevQuestions.slice(0, index),
      ...prevQuestions.slice(index + 1),
    ]);
  };

  const handleAddAnswer = () => {
    if (currentChoices) {
      setCurrentChoices((prevChoices) => [...prevChoices, ""]); // Updated variable name
    } else {
      setCurrentChoices([""]); // Updated variable name
    }
  };

  const handleUpdateAnswer = (index, value) => {
    setCurrentChoices((prevChoices) => [
      ...prevChoices.slice(0, index),
      value,
      ...prevChoices.slice(index + 1),
    ]); // Updated variable name
  };

  const handleDeleteAnswer = (index) => {
    setCurrentChoices((prevChoices) => [
      ...prevChoices.slice(0, index),
      ...prevChoices.slice(index + 1),
    ]); // Updated variable name
  };

  const handleOpenModal = () => {
    setCurrentQuestion("");
    setCurrentChoices([]);
    setQuestionType("single_selected_choices");
    setAnswerType("numeric");
    setQuestionStatus("drafted");
    setQuestionCode(makeid(10))
    onOpen();
  };

  const handlePreviewQuestion = (index) => {
    setPreviewIndex(index);
    onPreviewOpen();
  };

  const handleNextQuestion = () => {
    if (previewIndex < questions.length - 1) {
      setPreviewIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (previewIndex > 0) {
      setPreviewIndex((prevIndex) => prevIndex - 1);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "published":
        return "green.600";
      case "drafted":
        return "orange.600";
      // Add more cases as needed
      default:
        return "gray.600";
    }
  };

  const BreadCrumb = (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink color="blue.600" href="/home">
          รายการ event ทั้งหมด
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink color="blue.600" href={`/event?eid=${eventID}`}>
          {eventName}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {checkpointID && checkpointName && (
        <BreadcrumbItem>
          <Text>คำถามสำหรับ {checkpointName}</Text>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );

  return (
    <>
      <Flex pt={1} pb={1} pl={4} bgColor="gray.200">
        {BreadCrumb}
      </Flex>
      <Box p={2} w="full">
        <Flex mb={2} w="full" justify={"space-between"} align={"center"}>
          <Button
            colorScheme="blue"
            leftIcon={<AddIcon color="white" />}
            mr={1}
            onClick={handleOpenModal}
          >
            Add Question
          </Button>

          {questions.length > 0 && <Button
            variant={"outline"}
            colorScheme="blue"
            leftIcon={<ViewIcon color="blue.600" />}
            onClick={() => handlePreviewQuestion(0)}
          >
            Preview All Questions
          </Button>}
          <Spacer />
          <Button
            colorScheme="teal"
            leftIcon={<MdSave color="white" />}
            onClick={() => saveCheckpointQuestion()}
          >
            Save change to DB
          </Button>

          <Switch
            ml={4}
            colorScheme="green"
            isChecked={isSatisfactionEnabled}
            onChange={(e) => {
              const checked = e.target.checked;
              setIsSatisfactionEnabled(checked);

              if (checked) {
                openRatingModal();
              }

              //save the new state to the database
              saveEvaluateSatisfaction(checkpointID, checked ? 1 : 0);
            }}
          >
            แสดงแบบประเมินความพึงพอใจ
          </Switch>
      
        </Flex>

        <Table variant="striped">
          <Thead>
            <Tr>
              <Th w="450px">Question</Th>
              <Th w="150px">Type</Th>
              <Th w="200px">Status</Th>
              <Th w="200px">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {questions.length > 0 && questions.map((q, index) => (
              <Tr key={index}>
                <Td>{q.question}</Td>
                <Td>
                  {q.type === "single_selected_choices"
                    ? "Multiple Choice"
                    : "Answer Filling"}
                </Td>
                <Td color={getStatusColor(q.status)}>{q.status}</Td>
                <Td>
                  <IconButton
                    icon={<ChevronUpIcon />}
                    size="sm"
                    marginRight="1"
                    colorScheme="blue"
                    onClick={() => handleMoveQuestionUp(index)}
                  />
                  <IconButton
                    icon={<ChevronDownIcon />}
                    size="sm"
                    marginRight="1"
                    colorScheme="blue"
                    onClick={() => handleMoveQuestionDown(index)}
                  />
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    colorScheme="teal"
                    marginRight="1"
                    onClick={() => handleEditQuestion(index)}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    marginRight="1"
                    onClick={() => showDeleteConfirmation(index)}
                  />
                  {/* Add the new "Preview" button */}
                  <IconButton
                    icon={<ViewIcon />}
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handlePreviewQuestion(index)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {searchParams.get("cid") && <CheckpointProductList checkpointId={searchParams.get("cid")} />}

        {/* Modal for adding/editing questions */}
        <Modal isOpen={isOpen} size={"4xl"} onClose={onCloseEditModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add/Edit Question</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                <Textarea
                  placeholder="Enter your question"
                  value={currentQuestion}
                  onChange={(e) => setCurrentQuestion(e.target.value)}
                  rows={4}
                />
                <RadioGroup
                  onChange={handleQuestionTypeChange}
                  value={questionType}
                >
                  <Stack direction="row">
                    <Radio value="single_selected_choices">Multiple Choice</Radio>
                    <Radio value="filling">Answer Filling</Radio>
                  </Stack>
                </RadioGroup>
                {questionType === "single_selected_choices" ? (
                  <>
                    {currentChoices.map((choice, index) => (
                      <Stack key={index} direction="row" alignItems="center">
                        <IconButton
                          icon={<ChevronUpIcon />}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleMoveAnswerUp(index)}
                        />
                        <IconButton
                          icon={<ChevronDownIcon />}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleMoveAnswerDown(index)}
                        />
                        <Textarea
                          placeholder={`Answer ${index + 1}`}
                          value={choice}
                          onChange={(e) =>
                            handleUpdateAnswer(index, e.target.value)
                          }
                          rows={2}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDeleteAnswer(index)}
                          marginLeft="2"
                        />
                      </Stack>
                    ))}

                    <Button onClick={handleAddAnswer}>Add Answer</Button>
                  </>
                ) : (
                  // For "Answer Filling" mode
                  <>
                    <RadioGroup visibility={"hidden"} onChange={setAnswerType} value={answerType}>
                      <Stack direction="row">
                        <Radio value="numeric">Numeric</Radio>
                        <Radio value="string">String</Radio>
                      </Stack>
                    </RadioGroup>
                  </>
                )}
                <RadioGroup onChange={setQuestionStatus} value={questionStatus}>
                  <Stack direction="row">
                    <Radio value="published">Public</Radio>
                    <Radio value="drafted">Draft</Radio>
                  </Stack>
                </RadioGroup>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Flex justify={"space-between"} w="full">
                <Text color="blue.500">การบันทึก draft เป็นเพียงการบันทึกชั่วคราวลงในตารางเท่านั้น หากต้องการบันทึกการเปลี่ยนแปลงแบบถาวร กรุณากดเลือก "Save change to DB"</Text>
                <Spacer mr={20}/>
                <Button colorScheme="blue" onClick={handleAddQuestion}>
                  Save draft
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal for previewing questions */}
        {questions.length > 0 && <Modal size="3xl" isOpen={isPreviewOpen} onClose={onClosePreview}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center" fontSize="2xl">
              Preview Question {previewIndex + 1}/{questions.length}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Display the preview of the question at index previewIndex */}
              <Box>
                <Text fontSize="xl" fontWeight="bold" mb="4">
                  {questions[previewIndex].question}
                </Text>
                {questions[previewIndex].type === "single_selected_choices" ? (
                  <Stack spacing="2">
                    {questions[previewIndex].choices.map((choice, index) => (
                      <Box
                        key={index}
                        p="2"
                        borderRadius="5px"
                        borderWidth="1px"
                        borderColor="blue.600"
                      >
                        <Text>{`Choice ${index + 1}: ${choice}`}</Text>
                      </Box>
                    ))}
                    <Text fontSize="lg">Type: Multiple Choices</Text>
                  </Stack>
                ) : (
                  <Text fontSize="lg">Type: Answer Filling</Text>
                )}
                <Flex mt={3}>
                  <Text fontSize="lg" mr={2} color="gray.600">
                    Status:
                  </Text>
                  <Text
                    fontSize="lg"
                    color={getStatusColor(questions[previewIndex].status)}
                  >
                    {questions[previewIndex].status}
                  </Text>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <Button
                onClick={handlePreviousQuestion}
                colorScheme="blue"
                variant="outline"
              >
                Previous
              </Button>
              <Button
                onClick={handleNextQuestion}
                colorScheme="blue"
                variant="outline"
              >
                Next
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>}
      </Box>
      <RatingModal isOpen={isRatingModalOpen} onClose={closeRatingModal} />
    </>
  );
};

export default QuestionList;
