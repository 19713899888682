import axios from 'axios';
import { server } from './connect';

export const GET_EVENT_BY_ID = `${server}/event/get_event_by_id`;
export const GET_CHECKPOINT_BY_EVENT_ID = `${server}/checkpoint/list_checkpoint_by_event_id`;
export const INSERT_NEW_CHECKPOINT = `${server}/checkpoint/insert_new_checkpoint`;
export const UPDATE_CHECKPOINT = `${server}/checkpoint/update_checkpoint`;
export const LIST_PRODUCT_BY_EVENT_ID = `${server}/product/list_all_web_products_by_event_id`;

export const SAVE_QUESTION = `${server}/question/save_questions`;
export const RETIREVE_QUESTIONNAIRE_BY_CHECKPOINT_ID = `${server}/question/retrieve_all_questionnaire_records_by_checkpoint_id`;

export const GET_EVALUATE_SATISFACTION = `${server}/checkpoint/get_evaluate_satisfaction`;
export const UPDATE_EVALUATE_SATISFACTION = `${server}/checkpoint/update_evaluate_satisfaction`;

export const LIST_PRODUCT_BY_CHECKPOINT_ID = `${server}/checkpoint_product/list_all_web_products_by_checkpoint_id`;

export function getEventByID(eid) {
    return axios.post(GET_EVENT_BY_ID, { eid });
}

export function getCheckpointsFromEventID(event_id){
    return axios.post(GET_CHECKPOINT_BY_EVENT_ID, { event_id });
}

export function listProductByCheckpointID(check_point_id){
    return axios.post(LIST_PRODUCT_BY_CHECKPOINT_ID, { check_point_id });
}

export function retrieveQuestionnaireByCHeckpointID(checkpoint_id){
    return axios.post(RETIREVE_QUESTIONNAIRE_BY_CHECKPOINT_ID, { checkpoint_id });
}

export function insertNewCheckpoint(checkpoint_name, description, score, lat, long, start_time, end_time, status, event_id){
    return axios.post(INSERT_NEW_CHECKPOINT, { checkpoint_name, description, score, lat, long, start_time, end_time, status, event_id });
}

export function updateCheckpoint(checkoint_id, checkpoint_name, description, score, lat, long, start_time, end_time, status){
    return axios.post(UPDATE_CHECKPOINT, { id: checkoint_id, checkpoint_name, description, score, lat, long, start_time, end_time, status });
}

export function listProductByEventID(event_id){
    return axios.post(LIST_PRODUCT_BY_EVENT_ID, { event_id });
}

export function saveQuestions(checkpoint_id, questions){
    return axios.post(SAVE_QUESTION, { checkpoint_id, questions });
}

export function getEvaluateSatisfaction(checkpoint_id){
    return axios.post(GET_EVALUATE_SATISFACTION, { checkpoint_id });
}

export function  updateEvaluateSatisfaction(checkpoint_id, evaluate_satisfaction){
    return axios.post(UPDATE_EVALUATE_SATISFACTION, { checkpoint_id, evaluate_satisfaction });
}


