// src/components/RatingModal.js
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

const RatingModal = ({ isOpen, onClose }) => {
  const ratings = [
    { emoji: '😡', label: 'แย่', color: 'red.500' },
    { emoji: '😞', label: 'พอใช้', color: 'orange.400' },
    { emoji: '😐', label: 'ปานกลาง', color: 'yellow.400' },
    { emoji: '😊', label: 'ดี', color: 'green.400' },
    { emoji: '😁', label: 'ดีมาก', color: 'green.600' },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>ระบบจะแสดงส่วนสำหรับประเมินความพึงพอใจดังภาพ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="space-around" alignItems="center" mt={4}>
            {ratings.map((rating, index) => (
              <Box
                key={index}
                textAlign="center"
                cursor="pointer"
                _hover={{ transform: 'scale(1.2)' }}
                transition="all 0.3s"
              >
                <Text fontSize="4xl" color={rating.color}>
                  {rating.emoji}
                </Text>
                <Text fontWeight="bold">{rating.label}</Text>
              </Box>
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RatingModal;
