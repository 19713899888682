import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

import { listProductByCheckpointID } from "../util/api";
import { server } from "../util/connect";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2";
import { setGlobalState } from "../share/share_states";

const CheckpointProductList = ({ checkpointId }) => {
  const [selectedProductImage, setSelectedProductImage] = useState(null);
  const {
    isOpen: isImageModalOpen,
    onOpen: openImageModal,
    onClose: closeImageModal,
  } = useDisclosure();
  const {
    isOpen: isAddProductModalOpen,
    onOpen: openAddProductModal,
    onClose: closeAddProductModal,
  } = useDisclosure();
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    product_name: "",
    description: "",
    required_point: "",
    total_number: "",
    status: "inactive",
    check_point_id: checkpointId,
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");
  const toast = useToast();

  useEffect(() => {
    console.log(`checkpointId: ${checkpointId}`)
    const fetchProducts = async () => {
      try {
        setGlobalState("loadingtext", "loading, please wait...");
        setGlobalState("loading", true);
        const response = await listProductByCheckpointID(checkpointId);
        setProducts(response.data.products);
        setGlobalState("loading", false);
      } catch (error) {
        setGlobalState("loading", false);
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [checkpointId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var val = value;

    if(`${name}` == "total_number" && value == undefined){
      val = 0;
    }

    console.log(`${name}: ${val}`)
    setNewProduct((prevProduct) => ({ ...prevProduct, [name]: val }));
  };

  const renderRequiredStar = () => (
    <Text as="span" color="red.500">
      *
    </Text>
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const validateForm = () => {
    const { product_name, required_point, total_number } = newProduct;

    if (!product_name || !required_point || (total_number === "" || total_number == undefined || total_number < 0)) {
      setError("Please fill in all fields.");
      return false;
    }
    setError("");
    return true;
  };

  const handleAddProduct = async () => {
    try {
      if (validateForm()) {
        let newProductData = { ...newProduct };

        if (selectedImage) {
          const formData = new FormData();
          formData.append("file", selectedImage);

          const response = await axios.post(server + "/upload_file", formData);
          newProductData = {
            ...newProductData,
            image: response.data.image_name,
          };
        }

        // Add the product to the database
        await axios.post(server + "/checkpoint_product/add_new_checkpoint_product", newProductData);

        // Fetch updated product list
        const productListResponse = await listProductByCheckpointID(checkpointId);
        setProducts(productListResponse.data.products);

        // Close the modal
        closeAddProductModal();
        closeEditModal();
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleEditProduct = async () => {
    try {
      let newProductData = { ...newProduct };
      if (validateForm()) {
        // Upload image if a new image is selected
        if (selectedImage) {
          const formData = new FormData();
          formData.append("file", selectedImage);

          // Upload image and get the file name from the server
          const response = await axios.post(server + "/upload_file", formData);

          // Set the image URL in the selected product data
          newProductData = {
            ...newProductData,
            image: response.data.image_name,
          };
        }

        let updatedProductData = {
          ...selectedProduct,
          ...newProductData,
          id: selectedProduct.id,
        };

        // Update the product in the database
        setGlobalState("loadingtext", "saving, please wait...");
        setGlobalState("loading", true);
        await axios.post(server + `/checkpoint_product/update_checkpoint_product`, {
          ...updatedProductData,
        });

        // Fetch updated product list
        const productListResponse = await listProductByCheckpointID(checkpointId);
        setProducts(productListResponse.data.products);
        setGlobalState("loading", false);

        // Close the modal
        closeAddProductModal();
        closeEditModal();
      }
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error updating product:", error);
      Swal.fire("Error!", error, "error");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this product!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (value) {
        // User confirmed the deletion
        await axios.post(`${server}/checkpoint_product/delete_checkpoint_product`, { id: productId });

        // Fetch updated product list
        const productListResponse = await listProductByCheckpointID(checkpointId);
        setProducts(productListResponse.data.products);
        toast({
          title: "Deleted!",
          description: "Your product has been deleted.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the product.",
        "error"
      );
    }
  };

  const openEditModal = (product) => {
    setSelectedProduct(product);
    setNewProduct({
      product_name: product.product_name,
      description: product.description,
      required_point: product.required_point,
      status: product.status,
      total_number: product.total_number==undefined?0:product.total_number,
      check_point_id: checkpointId,
    });
    setSelectedImage(null); // Reset selected image
    openAddProductModal();
  };

  const closeEditModal = () => {
    console.log("Closing modal...");
    setSelectedProduct(null);
    setNewProduct({
      product_name: "",
      description: "",
      required_point: "",
      total_number: "",
      status: "",
      check_point_id: checkpointId,
    });
    setSelectedImage(null);
    setError(""); // Reset error message
    closeAddProductModal();
    console.log("Modal closed.");
  };

  const getStatusColor = (status) => {
    return (
      <Text color={status === "active" ? "green" : "orange.500"}>{status}</Text>
    );
  };

  return (
    <ChakraProvider>
      <Box mt={10} p={0}>
        <Button mb={4} variant={"outline"} colorScheme="teal" onClick={openAddProductModal}>
          Add Checkpoint Product
        </Button>

        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Image</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Required point</Th>
              <Th>Number</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products.length > 0 &&
              products.map((product) => (
                <Tr key={product.id}>
                  <Td>
                    <Image
                      boxSize="50px"
                      objectFit="cover"
                      src={server + "/product_images/icon_" + product.image}
                      alt={product.product_name}
                      onClick={() => {
                        setSelectedProductImage(
                          server + "/product_images/" + product.image
                        );
                        openImageModal();
                      }}
                      cursor="pointer"
                    />
                  </Td>
                  <Td>{product.product_name}</Td>
                  <Td>{product.description}</Td>
                  <Td>{product.required_point}</Td>
                  <Td>{product.total_number}</Td>
                  <Td>{getStatusColor(product.status)}</Td>
                  <Td>
                    <Tooltip
                      label={`Edit ${product.product_name}`}
                      placement="auto"
                    >
                      <IconButton
                        icon={<EditIcon />}
                        size="sm"
                        colorScheme="teal"
                        marginRight="2"
                        onClick={() => openEditModal(product)}
                      />
                    </Tooltip>

                    <Tooltip
                      label={`Delete ${product.product_name}`}
                      placement="auto"
                    >
                      <IconButton
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDeleteProduct(product.id)}
                        marginRight="2"
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        {/* Add/Edit Product Modal */}
        <Modal isOpen={isAddProductModalOpen} onClose={closeEditModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedProduct ? "Edit Product" : "Add Product"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {error && <Text color="red.500">{error}</Text>}

              <Text mb={2}>ชื่อสินค้า:{renderRequiredStar()}</Text>
              <Input
                placeholder=""
                name="product_name"
                value={newProduct.product_name}
                onChange={handleInputChange}
                mb={4}
              />

              <Text mb={2}>คำอธิบาย (description):</Text>
              <Input
                placeholder=""
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
                mb={4}
              />

              <Text mb={2}>
                ที่แนนสะสมที่ใช้ในการแลก:{renderRequiredStar()}
              </Text>
              <Input
                type="number"
                placeholder=""
                name="required_point"
                value={newProduct.required_point}
                onChange={handleInputChange}
                mb={4}
              />

              <Text mb={2}>จำนวนใน stock:{renderRequiredStar()}</Text>
              <Input
                type="number"
                placeholder=""
                name="total_number"
                value={newProduct.total_number}
                onChange={handleInputChange}
                mb={4}
              />

              <Text mb={2}>ภาพสินค้า:{renderRequiredStar()}</Text>
              <Input
                type="file"
                name="image"
                onChange={handleImageChange}
                mb={4}
              />

              <FormControl mt={3}>
                <FormLabel>Status {renderRequiredStar()}</FormLabel>
                <Select
                  name="status"
                  value={newProduct.status}
                  onChange={handleInputChange}
                >
                  <option value="inactive">Inactive</option>
                  <option value="active">Active</option>
                </Select>
              </FormControl>

            </ModalBody>
            <ModalFooter>
              {selectedProduct ? (
                <Button colorScheme="blue" onClick={handleEditProduct}>
                  บันทึกการแก้ไข
                </Button>
              ) : (
                <Button colorScheme="teal" onClick={handleAddProduct}>
                  เพิ่มสินค้า
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isImageModalOpen} onClose={closeImageModal} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Product Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedProductImage && (
                <Image
                  boxSize="100%"
                  objectFit="contain"
                  src={selectedProductImage}
                  alt="Product"
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default CheckpointProductList;
