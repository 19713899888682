
'use client'

import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import EventList from './EventList'
import { useAuth } from '../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import EventInfo from './EventInfo'
import SidebarContent from '../components/navbar/SidebarContent'
import MobileNav from '../components/navbar/MobileNav'
  
  const Home = () => {
    const { user, checkUser, login, logout } = useAuth();
    const [selectedMenu, setSelectedMenu] = useState('home');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedEvent, setSelectedEvent] = useState();
    const navigate = useNavigate()

    const onEventSelected = (event) => {
      setSelectedEvent(event)
      //alert(event.id)
    }
  
    const setMenu = (menu) => {
      setSelectedMenu(menu);
    };

    useEffect(()=>{
      if(checkUser() == null){
        navigate("/login")
      }
    }, [])

    const signout = () => {
      logout();
      navigate("/login")
    }

    const BreadCrumb = <Breadcrumb display={{ base: 'none', md: 'flex' }}>
          <BreadcrumbItem>
            <BreadcrumbLink href='#' onClick={()=>{setSelectedEvent(null)}}>รายการ event ทั้งหมด</BreadcrumbLink>
          </BreadcrumbItem>
          {selectedEvent && <BreadcrumbItem>
            <BreadcrumbLink href='#'>{selectedEvent.event_name}</BreadcrumbLink>
          </BreadcrumbItem>}
      </Breadcrumb>
  
    return (
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent
          onClose={() => onClose}
          setMenu={setMenu}
          selectedMenu={selectedMenu}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent setMenu={setMenu} onClose={onClose} selectedMenu={selectedMenu} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent} signout={signout} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">

          {/* content go here */}
          {selectedMenu === 'home' && !selectedEvent && <EventList BreadCrumb={BreadCrumb} onEventSelected={onEventSelected} />}
          {selectedMenu === 'home' && selectedEvent && <EventInfo BreadCrumb={BreadCrumb} eventData={selectedEvent} />}
          
        </Box>
      </Box>
    );
  };



export default Home